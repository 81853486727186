// inline scss
import '../scss/app.scss';

// jQuery
import $ from 'jquery';
window.$ = $;

// Outdated browsers
import browserUpdate from '../node_modules/browser-update/update.npm.full.js';

// Bootstrap
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

// Waypoints
import '../node_modules/waypoints/lib/jquery.waypoints.min.js';
import '../node_modules/waypoints/lib/noframework.waypoints.min.js'
import './scrollIndicators.js';

// Slick
import '../node_modules/slick-carousel/slick/slick.js'

//Custom
import browserUpdateParameters from './browserUpdate.js';
import './script.js';
import './custom.js';
import './animation.js';

$(() => {
    browserUpdate(browserUpdateParameters);
    // initCarousel();
    $(".regular").slick({
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: 'button.prevArrow',
        nextArrow: 'button.nextArrow',
        centerMode: false,
        centerPadding: 100,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-product').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-content'
    });

    $('.slider-content').slick({
        centerPadding: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider-product',
        dots: false,
        prevArrow: 'button.prevArrow',
        nextArrow: 'button.nextArrow',
        centerMode: true,
        focusOnSelect: true
    });

    $(".slider-logos").slick({
        autoplay:true,
        autoplaySpeed:3000,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        variableWidth: true,
        centerPadding: 100
    });

    $(window).on('resize', function() {
        $('.carousel').slick('resize');
    });

    $('.navbar-toggler-icon').click(function () {
        $('body').addClass('no-scroll-overlay');
    });

    $('.btn-close').click(function () {
        $('body').removeClass('no-scroll-overlay');
    });

    $(document).keydown(function(e){
        if(e.keyCode == 27) {
            if ($('.overlay-hugeinc').hasClass('open-modern')) {
                $('.overlay-hugeinc').removeClass('open-modern');
                $('body').removeClass('no-scroll-overlay');
            } else {

            }
        }
    });

    $('.testi-slider').slick({
        infinite: true,
        autoplaySpeed:3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: false,
        prevArrow: 'button#prevslide',
        nextArrow: 'button#nextslide',
        adaptiveHeight: true
    });

    $('.sodium-slider').slick({
        autoplaySpeed:3000,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: 'button#prevslide',
        nextArrow: 'button#nextslide',
        responsive: [
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    infinite: true
                }
            }
        ]
    });

    $('.scroll-section').scrollIndicatorBullets();
    /* VIDEO HOME */
if ($('video').length>0){
    $('video').each(function(i,video){
        var $video=$(video);
        var video=$video[0];
        var $buttonPlay=$video.prev('.button-play');
        var $nextSection=$video.parent().next();//devriat être une selection relative (.parent().parent

        $('.button-play,video').click(function(){
            video.paused ? $buttonPlay.hide() : $buttonPlay.show();
            video.paused ? video.play() : video.pause();
            video.paused ? $nextSection.removeClass('down') :$nextSection.addClass('down');
        });
        $("video").bind("ended", function() {
            $buttonPlay.show();
            this.currentTime = 0;
            $nextSection.removeClass('down')
        });
    });

}
    /*var iframe = document.querySelector('iframe');
    var player = new Vimeo.Player(iframe);

    player.on('play', function() {
        $("#food-section").addClass('down');
    });

    player.on('pause', function() {
        $("#food-section").removeClass('down');
    });

    $('.button-play').click(function(){
        player.play();
    });*/
});