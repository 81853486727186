export default {
  required: {
    //Edge
    e: -2,
    //Internet explorer
    i: 11,
    //Firefox
    f: -3,
    //Opera
    o: -3,
    //Safari
    s: 10.1,
    //Chrome
    c:"64.0.3282.16817",
    samsung: 7.0
  },
  insecure: true,
  unsupported: true,
  reminder: 0
};