import { TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

//ScrollMagic

$(function() {

// All blocks with heart
  var slide_heart = TweenMax.fromTo('.gt_heart_image', 1, {
    right: '-110%',
    opacity: 0
  }, {
    right: '0',
    opacity: 1,
    ease: Linear.easeNone
  });
  var slide_descr = TweenMax.fromTo('.descr', 1, {
    left: '-100%',
    opacity: 0
  }, {
    left: '0',
    opacity: 1,
    ease: Linear.easeNone
  });

// Sodium
  var slide_sodium = TweenMax.fromTo('.sodium', 1, {
    right: '-250px',
    opacity: 0
  }, {
    right: '0',
    opacity: 1,
    ease: Linear.easeNone
  });


// All blocks full img with heart (fade effect)

  // Home
  var fade_intro = TweenMax.fromTo('.ecosysteme .intro', 1, {
    opacity: '0'
  }, {
    opacity: '1',
    ease: Linear.easeNone
  });

  // Salines
  var fade_intro_salines = TweenMax.fromTo('.soin .intro', 1, {
    opacity: '0'
  }, {
    opacity: '1',
    ease: Linear.easeNone
  });

  // Mission
  var fade_intro_mission = TweenMax.fromTo('.patrimoine-mondial .intro', 1, {
    opacity: '0'
  }, {
    opacity: '1',
    ease: Linear.easeNone
  });

  // Produits alimentaires et espace pro
  var fade_intro_produits = TweenMax.fromTo('.getintouch .intro', 1, {
    opacity: '0'
  }, {
    opacity: '1',
    ease: Linear.easeNone
  });

// init ScrollMagic Controller
  var controller = new ScrollMagic.Controller();

// Scenes


// All controller
  var scenes = [];
  if ($('.logo-food').length > 0) {

// All blocks with heart
    var slide_heart_scene = new ScrollMagic.Scene({
      triggerElement: '.logo-food'
    })
      .setTween(slide_heart);
    var slide_descr_scene = new ScrollMagic.Scene({
      triggerElement: '.logo-food'
    })
      .setTween(slide_descr);
    scenes.push(slide_heart_scene, slide_descr_scene);
  }

  if ($('.ecosysteme').length > 0) {

    // Home
    var fade_intro_scene = new ScrollMagic.Scene({
      triggerElement: '.ecosysteme'
    })
      .setTween(fade_intro);

    scenes.push(fade_intro_scene);
  }

  if ($('.soin').length > 0) {

    // Salines
    var fade_intro_salines_scene = new ScrollMagic.Scene({
      triggerElement: '.soin'
    })
      .setTween(fade_intro_salines);

    scenes.push(fade_intro_salines_scene);
  }

  if ($('.patrimoine-mondial').length > 0) {

    // Mission
    var fade_intro_mission_scene = new ScrollMagic.Scene({
      triggerElement: '.patrimoine-mondial'
    })
      .setTween(fade_intro_mission);

    scenes.push(fade_intro_mission_scene);
  }

  if ($('.getintouch').length > 0) {

    // Produits alimentaires et espace pro
    var fade_intro_produits_scene = new ScrollMagic.Scene({
      triggerElement: '.getintouch'
    })
      .setTween(fade_intro_produits);

    scenes.push(fade_intro_produits_scene);
  }

  if ($('.sodium').length > 0) {

    // Sodium
    var slide_sodium_scene = new ScrollMagic.Scene({
      offset: 0
    })
      .setTween(slide_sodium);

    scenes.push(slide_sodium_scene);
  }

  controller.addScene(scenes);

});