$().ready(function() {
  // open amCharts link in new tab, only on page where displayed
  var $benefitsPage = $('#efficacite-section');
  if ($benefitsPage.length > 0) {
    var $amChartsLink;
    var interval = setInterval(
      function() {
        $amChartsLink = $("a[title='JavaScript charts']");
        if ($amChartsLink.length > 0) {
          $amChartsLink.attr('target', '_blank');
          clearInterval(interval);
        }
      },
      1000
    );
  }
});