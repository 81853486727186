$(function() {
    (function () {
        var triggerBttn = document.getElementById('trigger-overlay'),
            overlay = document.querySelector('div.overlay'),
            closeBttn = overlay.querySelector('button.overlay-close'),
            transEndEventNames = {
                'WebkitTransition': 'webkitTransitionEnd',
                'MozTransition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'msTransition': 'MSTransitionEnd',
                'transition': 'transitionend'
            },
            transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
            support = {transitions: Modernizr.csstransitions};

        function toggleOverlay() {
            if (classie.has(overlay, 'open-modern')) {
                classie.remove(overlay, 'open-modern');
                classie.add(overlay, 'close-modern');
                var onEndTransitionFn = function (ev) {
                    if (support.transitions) {
                        if (ev.propertyName !== 'visibility') return;
                        this.removeEventListener(transEndEventName, onEndTransitionFn);
                    }
                    classie.remove(overlay, 'close-modern');
                };
                if (support.transitions) {
                    overlay.addEventListener(transEndEventName, onEndTransitionFn);
                }
                else {
                    onEndTransitionFn();
                }
            }
            else if (!classie.has(overlay, 'close-modern')) {
                classie.add(overlay, 'open-modern');
            }
        }

        triggerBttn.addEventListener('click', toggleOverlay);
        closeBttn.addEventListener('click', toggleOverlay);
    })()
});